<template>
  <div class="d-flex">
    <b-form-input type="text" :placeholder="placeholder" class="form-control" v-model="text1"/>
    <b-form-input type="text" :placeholder="placeholder" class="form-control ml-2" v-model="text2"/>
  </div>
  
</template>
<style lang="scss" scoped>

</style>
<script>
export default {
  props: {
    value: {
      required: true
    },
    maxlength: {
      type: [String, Number],
      default: "16"
    },
    placeholder: {
      type: String
    }
  },
  computed:{
    text1: {
      get(){
        if(this.value)
          return this.value.slice(0, 7);
        return "";
      },
      set(newVal){
        if (!newVal) { this.$emit('input', newVal); return; }
        var natural = newVal.replace(/[^\d]/g, "");
        if (natural.length < 5) { this.$emit('input', newVal); return; }
        if (natural.length < 6) { this.$emit('input', `${natural.slice(0, 5)}`); return; }
        this.$emit('input', `${natural.slice(0, 5)}-${natural.slice(5,6)}-${this.text2}`);
      }
    },
    text2: {
      get(){
        if(this.value && this.value.length > 7)
          return this.value.slice(8);
        return "";
      },
      set(newVal){
        this.$emit('input', `${this.text1}-${newVal}`);
      }
    }
  },
  methods: {}
};
</script>