<template>
  <span class="floating-menu-container">
    <b-form-input autocomplete="off" v-model="input" :placeholder="placeholder"/>  
    <div :class="{'floating-menu': true, 'show': show}">
      <div class='floating-menu-row' v-for="(row, index) in list" :key="index" @click="onSelect(row)">
        <slot :row="row"></slot>
      </div>
    </div>
  </span>
</template>

<script>
import { debounce } from '@/plugins/debounce';
export default {
  props: ['value', 'list', 'placeholder'],
  data(){
    return {
      input: this.value,
      show: false,
      emit: true
    }
  },
  watch: {
    value: function(value){
      this.emit = false;
      this.input = value;
    },
    input: debounce(function(value) {
      if(this.emit) this.$emit('input', value);
      else this.emit = true;
    },500),
    list: function(){
      this.show = true;
    }
  },
  methods: {
    onSelect(row){
      this.$emit('select', row);
      this.show = false;
    }
  }
}
</script>

<style scoped>
  .floating-menu-container {
    position: relative;
  }
  .floating-menu{
    position:absolute;
    min-width: 200px;
    max-height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
    display:none;
    background: #fff;
    z-index: 99;
  }
  .floating-menu.show{
    display:block;
  }
  .floating-menu .floating-menu-row{
    padding: 0.5rem 1rem;
  }
  .floating-menu .floating-menu-row:hover{
    background: #959EA7;
  }
  .floating-menu .floating-menu-row:not(:first-child){
    border-top: 1px solid #959EA7;
  }
</style>