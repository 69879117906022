<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">システム管理</router-link></li>
      <li><a>振込口座情報</a></li>
    </ul>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSave)">
        <div class="page">
          <div class="page-title">
            <h1>振込口座情報</h1>
            <p>貴社の振替口座情報をご記入ください。</p>
          </div>
          <div class="card biz-primary">
            <div class="card-header">
              振込口座
              <p>貴社の振替口座情報をご記入ください。</p>
            </div>
            <div class="card-body">
              <div class="biz-form-group seperated">
                  <label class="biz-form-label required">お申込区分</label>
                  <div class="biz-form-body">
                    <b-form-group class="biz-offset">
                      <b-form-radio v-model="form.applicationType" value="OTHER_BANK" class="d-inline-block" @change="onTypeChange">金融機関</b-form-radio>
                      <b-form-radio v-model="form.applicationType" value="JP_BANK" class="d-inline-block ml-4" @change="onTypeChange">ゆうちょ銀行</b-form-radio>
                    </b-form-group>
                  </div>
              </div>
              <div class="biz-form-group seperated mt-3" v-if="form.applicationType === 'OTHER_BANK'">
                <label class="biz-form-label required">銀行</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="銀行名">
                        <validation-provider name="銀行名" rules="required|max:15" v-slot="{ errors }">
                          <DropdownInput 
                            v-model="form.bankName" 
                            :list="bankNameList" 
                            placeholder="例）〇〇銀行" 
                            @input="onBankChange(form.bankName, null)" 
                            @select="onBankSelect">
                            <template v-slot="{row}">[{{row.code}}]{{row.name}}</template>
                          </DropdownInput>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="銀行コード">
                        <validation-provider name="銀行コード" rules="required|numeric|length:4" v-slot="{ errors }">
                          <DropdownInput 
                            v-model="form.bankCode" 
                            :list="bankCodeList" 
                            placeholder="例）XXXX" 
                            @input="onBankChange(null, form.bankCode)" 
                            @select="onBankSelect">
                            <template v-slot="{row}">[{{row.code}}]{{row.name}}</template>
                          </DropdownInput>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>              
                </div>
              </div>
              <div class="biz-form-group seperated mt-3">
                <label class="biz-form-label required">支店</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="支店名">
                        <validation-provider name="支店名" rules="required|max:15" v-slot="{ errors }">
                          <DropdownInput 
                            v-model="form.branchName" 
                            :list="branchNameList" 
                            placeholder="例）〇〇銀行支店" 
                            @input="onBranchChange(form.branchName, null)" 
                            @select="onBranchSelect">
                            <template v-slot="{row}">[{{row.code}}]{{row.name}}</template>
                          </DropdownInput>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="支店コード">
                        <validation-provider name="支店コード" rules="required|numeric|length:3" v-slot="{ errors }">
                          <DropdownInput 
                            v-model="form.branchCode" 
                            :list="branchCodeList" 
                            placeholder="例）XXX" 
                            @input="onBranchChange(null, form.branchCode)" 
                            @select="onBranchSelect">
                            <template v-slot="{row}">[{{row.code}}]{{row.name}}</template>
                          </DropdownInput>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>              
                </div>
              </div>
              <div class="biz-form-group mt-3">
                <label class="biz-form-label required">口座</label>
                <div class="biz-form-body">
                  <div class="row">
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="種別">
                        <validation-provider name="口座種別" rules="required" v-slot="{ errors }">
                          <b-form-radio v-model="form.accountType" value="DEPOSIT" class="d-inline-block">普通預金</b-form-radio>
                          <b-form-radio v-model="form.accountType" value="IMMEDIATE" class="d-inline-block ml-4">当座預金</b-form-radio>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="col-3">
                      <b-form-group class="biz-input-group" label="口座番号">
                        <template v-if="form.applicationType === 'OTHER_BANK'">
                          <validation-provider name="口座番号" rules="required|numeric|min:4|max:7" v-slot="{ errors }">
                            <b-form-input placeholder="例）XXXXXXX" v-model="form.accountNumber"/>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </validation-provider>
                        </template>
                        <template v-else>
                          <validation-provider name="口座番号" rules="required|min:13|max:16" v-slot="{ errors }">
                            <InputYucho placeholder="例）XXXXXXX" v-model="form.accountNumber"/>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </validation-provider>
                        </template>
                      </b-form-group>
                    </div>
                    <div class="col-5 d-flex">
                      <b-form-group class="biz-input-group" label="口座名義">
                        <validation-provider name="口座名義" rules="required|max:30" v-slot="{ errors }">
                          <b-form-input class="d-inline-block" placeholder="例）ジーニーラボ株式会社" v-model="form.accountName"/>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group class="biz-input-group ml-4" label="口座名義（カナ）">
                        <validation-provider name="口座名義（カナ）" rules="required|max:30" v-slot="{ errors }">
                          <b-form-input class="d-inline-block" placeholder="例）ジーニーラボ（カ" v-model="form.accountNameKana"/>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>              
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <b-form-checkbox v-model="accepted" :value="true" :unchecked-value="false">記入した情報が正しいことを確認しました。</b-form-checkbox>
            <j-button class="mt-3" variant="primary" size="lg" :disabled="!accepted" buttonType="submit">
              {{isRegistrationWizard?'次へ':'保存'}}
            </j-button>
            <div class="mt-4" v-if="!isRegistrationWizard">
              <j-button variant="light" to="/supplier/manage">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                前へ戻る
              </j-button>
            </div>
          </div>
        </div>
      </b-form>
    </ValidationObserver>
</div>
</template>

<style scoped lang="scss">

</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo';
  import Bank from '@/model/Bank';
  import InputYucho from '@/components/input/InputYucho';
  import DropdownInput from '@/components/common/DropdownInput';
  
  export default {
    props: ["isRegistrationWizard"],
    components: { InputYucho, DropdownInput },
    data: () => ({
      form: {},
      bankNameList: [],
      bankCodeList: [],
      branchNameList: [],
      branchCodeList: [],
      accepted: false
    }),
    mounted: function(){
      CompanyInfo.getCompanyBankAccount(this.$store.state.user.companyCode).then(result => {
        this.form = result.data;
        if(!this.form.applicationType){
          this.form.applicationType = "OTHER_BANK";
        }
      });
    },
    methods:{
      onTypeChange(){
        if(this.form.applicationType === "JP_BANK"){
          this.form.bankCode = null;
          this.form.bankName = null;
        }
      },
      onBankChange(bankName, bankCode){
        if(bankName || bankCode)
          Bank.getBank(bankName, bankCode).then(result => {
            if(bankName) this.bankNameList = result.data;
            else this.bankCodeList = result.data;
          }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onBranchChange(branchName, branchCode){
        if(branchName || branchCode)
          Bank.getBranch(this.form.bankCode, branchName, branchCode).then(result => {
            if(branchName) this.branchNameList = result.data;
            else this.branchCodeList = result.data;
          }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onBankSelect(row){
        this.form.bankCode = row.code;
        this.form.bankName = row.name;
      },
      onBranchSelect(row){
        this.form.branchCode = row.code;
        this.form.branchName = row.name;
      },
      onSave(){
        CompanyInfo.saveCompanyBankAccount(this.$store.state.user.companyCode, this.form).then(() => {
          this.$successMessage("振込口座情報の登録が完了しました。")
          this.$emit('onSave', 'CategoryInfo');
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }
    }
  }
</script>