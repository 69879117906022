var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isRegistrationWizard)?_c('ul',{staticClass:"biz-breadcrumb"},[_c('li',[_c('router-link',{attrs:{"to":"/supplier/manage"}},[_vm._v("システム管理")])],1),_vm._m(0)]):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSave)}}},[_c('div',{staticClass:"page"},[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v("振込口座情報")]),_c('p',[_vm._v("貴社の振替口座情報をご記入ください。")])]),_c('div',{staticClass:"card biz-primary"},[_c('div',{staticClass:"card-header"},[_vm._v(" 振込口座 "),_c('p',[_vm._v("貴社の振替口座情報をご記入ください。")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"biz-form-group seperated"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("お申込区分")]),_c('div',{staticClass:"biz-form-body"},[_c('b-form-group',{staticClass:"biz-offset"},[_c('b-form-radio',{staticClass:"d-inline-block",attrs:{"value":"OTHER_BANK"},on:{"change":_vm.onTypeChange},model:{value:(_vm.form.applicationType),callback:function ($$v) {_vm.$set(_vm.form, "applicationType", $$v)},expression:"form.applicationType"}},[_vm._v("金融機関")]),_c('b-form-radio',{staticClass:"d-inline-block ml-4",attrs:{"value":"JP_BANK"},on:{"change":_vm.onTypeChange},model:{value:(_vm.form.applicationType),callback:function ($$v) {_vm.$set(_vm.form, "applicationType", $$v)},expression:"form.applicationType"}},[_vm._v("ゆうちょ銀行")])],1)],1)]),(_vm.form.applicationType === 'OTHER_BANK')?_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("銀行")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"銀行名"}},[_c('validation-provider',{attrs:{"name":"銀行名","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropdownInput',{attrs:{"list":_vm.bankNameList,"placeholder":"例）〇〇銀行"},on:{"input":function($event){return _vm.onBankChange(_vm.form.bankName, null)},"select":_vm.onBankSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("["+_vm._s(row.code)+"]"+_vm._s(row.name))]}}],null,true),model:{value:(_vm.form.bankName),callback:function ($$v) {_vm.$set(_vm.form, "bankName", $$v)},expression:"form.bankName"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"銀行コード"}},[_c('validation-provider',{attrs:{"name":"銀行コード","rules":"required|numeric|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropdownInput',{attrs:{"list":_vm.bankCodeList,"placeholder":"例）XXXX"},on:{"input":function($event){return _vm.onBankChange(null, _vm.form.bankCode)},"select":_vm.onBankSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("["+_vm._s(row.code)+"]"+_vm._s(row.name))]}}],null,true),model:{value:(_vm.form.bankCode),callback:function ($$v) {_vm.$set(_vm.form, "bankCode", $$v)},expression:"form.bankCode"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])]):_vm._e(),_c('div',{staticClass:"biz-form-group seperated mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("支店")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"支店名"}},[_c('validation-provider',{attrs:{"name":"支店名","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropdownInput',{attrs:{"list":_vm.branchNameList,"placeholder":"例）〇〇銀行支店"},on:{"input":function($event){return _vm.onBranchChange(_vm.form.branchName, null)},"select":_vm.onBranchSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("["+_vm._s(row.code)+"]"+_vm._s(row.name))]}}],null,true),model:{value:(_vm.form.branchName),callback:function ($$v) {_vm.$set(_vm.form, "branchName", $$v)},expression:"form.branchName"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"支店コード"}},[_c('validation-provider',{attrs:{"name":"支店コード","rules":"required|numeric|length:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DropdownInput',{attrs:{"list":_vm.branchCodeList,"placeholder":"例）XXX"},on:{"input":function($event){return _vm.onBranchChange(null, _vm.form.branchCode)},"select":_vm.onBranchSelect},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("["+_vm._s(row.code)+"]"+_vm._s(row.name))]}}],null,true),model:{value:(_vm.form.branchCode),callback:function ($$v) {_vm.$set(_vm.form, "branchCode", $$v)},expression:"form.branchCode"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])]),_c('div',{staticClass:"biz-form-group mt-3"},[_c('label',{staticClass:"biz-form-label required"},[_vm._v("口座")]),_c('div',{staticClass:"biz-form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"種別"}},[_c('validation-provider',{attrs:{"name":"口座種別","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{staticClass:"d-inline-block",attrs:{"value":"DEPOSIT"},model:{value:(_vm.form.accountType),callback:function ($$v) {_vm.$set(_vm.form, "accountType", $$v)},expression:"form.accountType"}},[_vm._v("普通預金")]),_c('b-form-radio',{staticClass:"d-inline-block ml-4",attrs:{"value":"IMMEDIATE"},model:{value:(_vm.form.accountType),callback:function ($$v) {_vm.$set(_vm.form, "accountType", $$v)},expression:"form.accountType"}},[_vm._v("当座預金")]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-3"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"口座番号"}},[(_vm.form.applicationType === 'OTHER_BANK')?[_c('validation-provider',{attrs:{"name":"口座番号","rules":"required|numeric|min:4|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"例）XXXXXXX"},model:{value:(_vm.form.accountNumber),callback:function ($$v) {_vm.$set(_vm.form, "accountNumber", $$v)},expression:"form.accountNumber"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]:[_c('validation-provider',{attrs:{"name":"口座番号","rules":"required|min:13|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputYucho',{attrs:{"placeholder":"例）XXXXXXX"},model:{value:(_vm.form.accountNumber),callback:function ($$v) {_vm.$set(_vm.form, "accountNumber", $$v)},expression:"form.accountNumber"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]],2)],1),_c('div',{staticClass:"col-5 d-flex"},[_c('b-form-group',{staticClass:"biz-input-group",attrs:{"label":"口座名義"}},[_c('validation-provider',{attrs:{"name":"口座名義","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"例）ジーニーラボ株式会社"},model:{value:(_vm.form.accountName),callback:function ($$v) {_vm.$set(_vm.form, "accountName", $$v)},expression:"form.accountName"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"biz-input-group ml-4",attrs:{"label":"口座名義（カナ）"}},[_c('validation-provider',{attrs:{"name":"口座名義（カナ）","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"例）ジーニーラボ（カ"},model:{value:(_vm.form.accountNameKana),callback:function ($$v) {_vm.$set(_vm.form, "accountNameKana", $$v)},expression:"form.accountNameKana"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])])])]),_c('div',{staticClass:"text-center mt-5"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.accepted),callback:function ($$v) {_vm.accepted=$$v},expression:"accepted"}},[_vm._v("記入した情報が正しいことを確認しました。")]),_c('j-button',{staticClass:"mt-3",attrs:{"variant":"primary","size":"lg","disabled":!_vm.accepted,"buttonType":"submit"}},[_vm._v(" "+_vm._s(_vm.isRegistrationWizard?'次へ':'保存')+" ")]),(!_vm.isRegistrationWizard)?_c('div',{staticClass:"mt-4"},[_c('j-button',{attrs:{"variant":"light","to":"/supplier/manage"}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" 前へ戻る ")],1)],1):_vm._e()],1)])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',[_vm._v("振込口座情報")])])}]

export { render, staticRenderFns }