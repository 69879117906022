import Model from './_base';

export default class Bank extends Model {
    static entity = 'bank';
    
    constructor() {
        super(Bank.entity);
    }
    
    static getBank(name, code){
      name = name || '';
      code = code || '';
      return Model.axios.get(this.url('?bankName='+ name + '&bankCode=' + code), { noLoading: true });
    }

    static getBranch(bankCode, name, code){
      name = name || '';
      code = code || '';
      return Model.axios.get(this.url('/'+bankCode+'/branches?branchName='+name + '&branchCode=' + code), { noLoading: true });
    }
    
}